.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: black;
}

.nav-bar-button {
  background-color: white;
  margin-top: 16px;
  height: 56px;
  width: 176px;
  margin-right: 20px;
  border-radius: 12px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.disconnect-button {
  height: 55px;
  width: 176px;
  background: #e86060;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 20%);
  border-radius: 12px;
  right: 20px;
  top: 80px;
  z-index: 1;
  position: fixed;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.nav-bar-logo {
  height: 64px;
  margin-left: 72px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.container {
  background-size: cover;
  background-image: url("./asset/background.svg");
  width: 100%;
  top: 88px;
  position: absolute;
  bottom: 0px;
}

.swap-button {
  height: 60px;
  width: 300px;

  background: #e8ad60;
  border: 5px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Inter;

  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 0px;

  text-align: center;
  letter-spacing: 0.004px;
  color: #000000;
  cursor: pointer;
  margin-top: 20px;
}

.swap-button.disab {
  background: grey;
  cursor: not-allowed;
}

.text-container {
  position: absolute;
  bottom: 25px;
  left: 5%;
  color: white;
  text-align: start;
}

.function-container {
  z-index: 999;
  margin-top: 18%;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./static/Inter-Regular.ttf) format("truetype");
}

.box-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.box-wrapper {
  width: 560px;
  height: 180px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
}

.left-box {
  margin-right: 8px;
}
.right-box {
  margin-left: 8px;
}
.right-box.disab {
  border: 3px solid #d71d1d;
}
.arrow-container {
  position: absolute;
}
.text-container-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
}

.text-container-body {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}

.tip-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #000000;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  padding-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 15px;
}
.styled-select select {
  background: transparent;
  border: 0px;
  margin-right: 1rem;
  padding: 1rem;
  padding-right: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.token-list ul {
  width: 480px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.token-list li {
  padding-left: 20px;
  padding-right: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  border-radius: 12px;
}

.token-list .item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.token-list .token-icon {
  /* height: 40px; */
}

.token-list .token-text {
  color: #000000;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-left: 10px;
}

.token-list .token-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #86909c;
}

.token_input {
  background-color: transparent;
  border: 0px solid;
  color: #000000;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  direction: rtl;
}

.token_input:focus {
  outline: none;
  text-align: right;
  direction: ltr;
}

.close-button {
  height: 60px;
  width: 80%;
  margin-bottom: 25px;
  color: #ffffff;
  background: #000000;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  cursor: pointer;
  background: #000000;
  border-radius: 20px;
}

.ant-modal-body {
  padding-bottom: 10px;
}

.ant-spin-dot-item {
  background-color: grey;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.modal-wiggy {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  width: 70%;
}

@media screen and (min-width: 0px) and (max-width: 1600px) {
  .text-container-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .text-container-body {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
